<!--  -->
<template>
  <div class="div-main">
    <div class="box">
      <div class="box-header">
        <div style="flex: 1;">
          <p class="box-title">用户管理</p>
          <p class="box-tip">已被添加至用户白名单的账户可使用所有该企业所有数字员工</p>
        </div>
        <div class="add-btn" @click="show3 = true">
          <img class="addbtn-img" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/adduser.png">
          <p class="addbtn-p" @click="show3 = true">添加用户</p>
        </div>
      </div>

      <div class="div-tabel">
        <div class="tabel-title">
          <p style="flex: 2;">用户手机号</p>
          <p style="flex: 2;">添加日期</p>
          <p style="flex: 1;">操作</p>

        </div>
        <div class="empty-tabel" v-if="userList.length == 0">暂无用户</div>

        <div class="table-list">
          <div class="tabel-content" v-for="(item, index) in userList" :key="item.id">
            <p style="flex: 2;">{{ item.phone }}</p>
            <p style="flex: 2;">{{ item.createDateLabel }}</p>
            <div style="flex:1">
              <div class="btn-delete" @click="deleteConfim(index)">删除</div>
            </div>



          </div>

        </div>

      </div>

    </div>

    <el-dialog :visible.sync="show3" :show-close="false">
      <div class="div-dialog">
        <div class="dialog-head">
          <p class="dialog-head-p">添加用户</p>
          <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="show3 = false">
        </div>
        <div class="dialog-line"></div>
        <div class="div-input"> <el-input type="textarea" :placeholder="placetxt" v-model="content" oninput="value=value.replace(/[^0-9\r\n]/g,'')">
          </el-input></div>
        <div class="dialog-line"></div>


        <div class="dialog-bottom">

          <button class="dialog-negative" @click="show3 = false">取消</button>
          <button class="dialog-positive" @click="addUser">确认</button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { rollList, deleteRoll, addNumbers } from "api/aiworker/aiworker.js";
import common from "common/js/common";
export default {
  data () {
    return {
      userList: [],
      orgId: null,
      show3: false,
      content: '',
      placetxt: '请输入手机号添加用户，同时添加多个用户使用【换行】后输入\n示例:\n12345678912\n12345678912'
    }
  },
  methods: {
    deleteConfim (index) {

      deleteRoll(this.userList[index].id).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.userList.splice(index, 1);
          this.$message({
              message: '删除成功',
              type: 'success'
            });
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },
    addUser () {
      if (common.isStringEmpty(this.content)) {
        this.$message('请输入手机号');
      } else {
        let phoneList1 = this.content.split('\n').filter(function (s) {
          return s && s.trim();
        });
        console.log(phoneList1);
        let obj = {
          orgId: this.orgId,
          phoneList: phoneList1
        }
        addNumbers(obj).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.content = '';
            this.show3 = false;
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.refreshUserList();
          } else {

            if (!common.isStringEmpty(res.message)) {
              this.$message(res.message);
            }
          }
        }).catch((err) => {

          if (!common.isStringEmpty(JSON.stringify(err))) {
            this.$message(JSON.stringify(err.message));
          }
        });
      }
    },
    refreshUserList () {
      rollList(this.orgId).then((res) => {
        console.log(res);
        if (res.code == 200) {

          this.userList = res.data;
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err));
        }
      });
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.orgId = this.$Base64.decode(this.$route.query.oid);
    rollList(this.orgId).then((res) => {
      console.log(res);
      if (res.code == 200) {

        this.userList = res.data;
      } else {

        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }
      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
  }
}
</script>

<style scoped>
/* @import url(); 引入css类 */
.div-main{
  padding-top: 20px;
  padding-bottom: 20px;
}
.box {

  display: flex;
  flex-direction: column;
  height: 980px;
  width: 1200px;
  margin: auto;

  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(9, 56, 128, 0.05);
  border-radius: 12px 12px 12px 12px;
}

.box-header {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 60px;
}

.box-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}

.box-tip {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 8px;
}

.add-btn {
  display: flex;
  width: 124px;
  height: 46px;
  justify-content: center;
  align-items: center;
  background: #F0F6FF;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
}

.addbtn-img {
  width: 24px;
  height: 24px;
}

.addbtn-p {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}

.div-tabel {

  max-height: 750px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
}

.tabel-title {
  width: 100%;
  display: flex;
  height: 50px;
  background: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  align-items: center;
  justify-content: space-between;
}

.tabel-title p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 20px;

}

.empty-tabel {
  height: 50px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 50px;
}

.table-list {
  width: 100%;
  max-height: 700px;
  overflow: auto;
}

.tabel-content {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #E9EEF4;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tabel-content p {
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-left: 20px;
}

.btn-delete {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  width: 70px;
  height: 38px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #FCF0E6;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #F44924;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

/deep/.el-dialog {

  width: 500px;
  height: 524px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  height: 100%;
  padding: 0;
}

.pop-content {
  display: flex;
}

.div-dialog {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog {
  width: 36px;
  height: 36px;


}

.dialog-head {
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-right: 6px;
}

.dialog-head-p {
  flex: 1;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-left: 20px;

}

.dialog-line {
  width: 100%;
  height: 1px;
  background-color: #E9EEF4;
}


.dialog-bottom {
  display: flex;
  margin-top: 15px;
  margin-left: 97px;
  margin-right: 97px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
  cursor: pointer;
}

.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
  cursor: pointer;
}

.div-input {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 25px;
}

/deep/ .el-textarea__inner {
  height: 340px;
  font-size: 16px;
  color: #000000;


  box-sizing: border-box;
  background: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
}

/deep/ .el-textarea__inner:focus {
  border-color: #409EFF;
  outline: 0;
}</style>